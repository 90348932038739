import { environment } from '../../environments/environment';

export const AppConfig = {
  apiUrl: environment.apiUrl,
  version: '1.1.3',
  isTest: !environment.production && environment.isTest,
  defaultStorageMode: 'local',
  cambioValutaHUF: 330.02,
  googleClientId: '21552991805-tdrsuif8ev00p1lk7thcdg1gouc7ch18.apps.googleusercontent.com',
  bugsnagApiKey: '4a50802e229df8de39c0beef52f59e2c',
};
